


































import Button from '@/components/button.vue';
import FormInput from '@/components/form-input.vue';
import IbanInput from '@/components/iban-input.vue';
import OfferOrdered from '@/components/offer-ordered.vue';
import ReadMore from '@/components/read-more.vue';
import {Component} from '@/decorators';
import {
    IBANSubscriptionUpdate,
    PortalSettings,
    PublicDeal,
    PublicSubscriptionPortalOffer,
    PublicVisit
} from '@/model/backend/campaign-api';
import Vue from 'vue';
import {Action, Getter} from 'vuex-class';

@Component({
    components: {IbanInput, FormInput, Button, ReadMore, OfferOrdered}
})
export default class IbanView extends Vue {
    @Getter
    private offer: PublicSubscriptionPortalOffer;

    @Getter
    private settings: PortalSettings;

    @Getter
    private visit: PublicVisit;

    @Getter
    private deal: PublicDeal;

    @Getter
    private upgraded: boolean;

    @Action
    private addIbanToSubscription: (payload: IBANSubscriptionUpdate) => Promise<void>;

    private depositor: string = '';

    private iban: string = '';

    public created(): void {
        if (!this.available) {
            this.next();
        }
    }

    public mounted() {
        this.depositor = `${this.visit.contact.firstName} ${this.visit.contact.lastName}`;
    }

    private submit() {
        if (!(this.$refs.depositorInput as FormInput).checkValidation()) {
            return;
        }

        if (!(this.$refs.ibanInput as IbanInput).checkValidation()) {
            return;
        }

        this.addIbanToSubscription({iban: this.iban, depositor: this.depositor}).then(this.next);
    }

    private next() {
        this.$router.push({
            name: 'thank-you-view',
            params: this.$route.params
        });
    }

    private get image(): string {
        if (this.settings.useV1Images) {
            return this.offer.iban.image;
        } else {
            return this.offer.iban.imageV1;
        }
    }

    private get available() {
        return this.offer.iban && (this.upgraded || !this.offer.iban.onlyActiveOnUpgrade);
    }

    private get oldPortalUrl(): string {
        return `/portal#/${this.$route.params.visitId}/subscription/iban/${this.$route.params.offerId}`;
    }
}
