var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.available
    ? _c(
        "div",
        { staticClass: "iban-view container mt-4" },
        [
          !_vm.offer.upgrade && !_vm.offer.iban.forceIbanStep
            ? _c("offer-ordered", {
                attrs: {
                  "bullet-points": _vm.offer.form.details.bulletPoints,
                  description: _vm.offer.thankYou.description,
                  image: _vm.offer.form.productImage,
                  title: _vm.offer.thankYou.headline
                }
              })
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _vm.offer.iban.activationHeadline
                ? _c("h2", [_vm._v(_vm._s(_vm.offer.iban.activationHeadline))])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [_vm._m(0)]),
            _c(
              "div",
              { staticClass: "col-md-6 iban-form" },
              [
                _vm.offer.iban.description
                  ? _c("ReadMore", {
                      attrs: { cutFrom: 30, value: _vm.offer.iban.description }
                    })
                  : _vm._e(),
                _c("form-input", {
                  ref: "depositorInput",
                  attrs: {
                    dataType: "name",
                    fixedPlaceholder: "",
                    placeholder: "Kontoinhaber"
                  },
                  model: {
                    value: _vm.depositor,
                    callback: function($$v) {
                      _vm.depositor = $$v
                    },
                    expression: "depositor"
                  }
                }),
                _c("iban-input", {
                  ref: "ibanInput",
                  model: {
                    value: _vm.iban,
                    callback: function($$v) {
                      _vm.iban = $$v
                    },
                    expression: "iban"
                  }
                }),
                _vm._m(1),
                !_vm.offer.iban.forceIbanStep
                  ? _c("div", [_vm._m(2), _vm._m(3)], 1)
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("img", {
      staticClass: "iban-image img-fluid",
      attrs: { src: _vm.image, alt: "IBAN Image" }
    })
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("Button", {
      attrs: { data: _vm.offer.iban.addButton, fill: "", type: "custom" },
      on: { click: _vm.submit }
    })
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "cancel-description" }, [
      _vm._v(_vm._s(_vm.offer.iban.cancelDescription))
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("Button", {
      staticClass: "cancel-button",
      attrs: { data: _vm.offer.iban.cancelButton, fill: "", type: "custom" },
      on: { click: _vm.next }
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }